<template>
  <div id="tab2" class="tab_content_area">
    <div class="img_wrap" style="height:300px;">
      <img src="../../../assets/images/trans/con_rf.png">
    </div>
    <div class="container_tbl_wrap">
      <div class="tbl_tit">{{ $t('msg.NEWB010P050.005') }}</div><!-- REFRIGERATED CONTAINER -->
      <div class="tbl_wrap">
        <table class="tbl_col">
          <colgroup>
            <col style="width:14%">
            <col style="width:auto">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Type of <br>Container</th>
              <th scope="col">Internal<br>Dimension<br>(mm)</th>
              <th scope="col">Door<br>Opening<br>(mm)</th>
              <th scope="col">Load<br>Capacity<br>(m<sup>3</sup>)</th>
              <th scope="col">Container<br>Tare Weight<br>(Kg)</th>
              <th scope="col">Max <br>Pay Load<br>(Kg)</th>
              <th scope="col">Max <br>Gross Weight<br>(Kg)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">20RF</th>
              <td>L: 5,454<br>W: 2,286<br>H: 2,245</td>
              <td>W: 2,286<br>H: 2,16</td>
              <td>28.5</td>
              <td>3,000</td>
              <td>22,000</td>
              <td>25,000</td>
            </tr>
            <tr>
              <th scope="row">40RF</th>
              <td>L: 11,583<br>W: 2,286<br>H: 2,215</td>
              <td>W: 2,294<br>H: 2,248</td>
              <td>58.7</td>
              <td>4,700</td>
              <td>25,780</td>
              <td>30,480</td>
            </tr>
            <tr>
              <th scope="row">40HCRF</th>
              <td>L: 11,583<br>W: 2,290<br>H: 2,542</td>
              <td>W: 2,290<br>H: 2,507</td>
              <td>67.4</td>
              <td>4,750</td>
              <td>25,780</td>
              <td>30,480</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div><!-- content_box // -->
</template>

<script>
export default {
  name: 'ContainerRF'
}
</script>

<style scoped>

</style>
